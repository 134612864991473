import React, { FC, useEffect, useState } from "react";
import { getChallengeBasedOnClient } from "../../../branchAndBrandSpecific/helperFunctions/getChallengeBasedOnClient";
import { useNavigate } from "react-router-dom";
import JBLPartnerPage from "./jbl.page";

const JBLAimTrialPage: FC = (): JSX.Element => {
  const [challengeLink, setChallengeLink] = useState<string>("");
  const [client, setCurrentClient] = useState("JBL");
  const navigate = useNavigate();

  // Fetch current active challenge logic
  const fetch = async () => {
    await getChallengeBasedOnClient(client, "ongoing").then((res) => {
      if (res && res.slug) {
        setChallengeLink(`/challenges/${res.slug}`);
      }
    });
  };

  // Fetch current active challenge on page load
  useEffect(() => {
    // Fetch current active challenge logic
    const fetch = async () => {
      await getChallengeBasedOnClient(client, "ongoing").then((res) => {
        if (res && res.slug) {
          setChallengeLink(`/challenges/${res.slug}`);
        }
      });
    };

    fetch();
  }, [client]);

  if (challengeLink) {
    navigate(challengeLink);
  }

  return <JBLPartnerPage />;
};

export default JBLAimTrialPage;
