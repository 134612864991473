const TRANSLATIONS_EN = {
  // Landing page
  landing: {
    hero: {
      headlineColored: "Challenge Your Friends",
      headline: "while playing games",
      description:
        "Track and measure different aspects of popular games while playing. Challenge your friends to most kills in CS2, longest distance kill in PUBG or fastest lap time in F1 2023. Challenges let you decide what to track.",
      cta: "Start playing now",
    },
    counter: {
      cta1: "Active games",
      cta2: "Competitive Challenges",
      cta3: "Customisable Criterias",
      support:
        "Currently supporting Counter-Strike 2, PUBG, F1 2023, Fall Guys and Forza Motorsport 8.",
    },
    heading: {
      headline: "Explore our features",
      description:
        "Unlock our features: level up your gameplay today! Dive into a world of gaming challenges and competitions on our platform.",
    },
    cta: {
      challenges: {
        headline: "Challenges",
        description:
          "Are you the best gamer amongst your friends? Find out by creating challenges and climbing the leaderboards!",
        button: "Start competing",
      },
      experience: {
        headline: "Gaming experience",
        description:
          "Your performance will be tracked automatically by our Game Tracker. After a one-time setup, you will be ready to compete in all games!",
        button: "Get the tracker",
      },
      social: {
        headline: "Social features",
        description:
          "Connect with friends or attract new followers while posting quality gaming content on your feed. Customize your profile, share epic moments and build your own community.",
        button: "Create your profile",
      },
      about: {
        headline: "About us",
        description:
          "We are a group of gamers that likes to compete. World of Gamers is a platform that makes gaming more fun!",
        button: "Learn more",
      },
    },
  },
  // Footer
  footer: {
    alert: {
      headline: "Ready to get started?",
      description: "Download our tracker and dominate the leaderboards!",
      button: "Download tracker",
      link: "Learn more",
    },
  },
  cookies: {
    headline: "Cookies",
    description:
      "We are using cookies to give you the best experience on our website.",
    more: "You can find out more about which cookies we are using on our",
    link: "Privacy Policy",
    essential:
      "Essential cookies: These cookies are necessary for our sites to work as intended.",
    optional:
      "Optional cookies: We reserve the right to use these cookies to get a better understanding of our users and hos they use our website.",
    button: {
      accept: "Accept all cookies",
      decline: "Decline optional cookies",
    },
  },
  leet: {
    headline: "Test your precision",
    objective: "Can you hit {{objective}} by clicking the button?",
    objective1: "Can you hit",
    objective2: "by clicking the button?",
    totalAttempts: "Total Attempts",
    start: "Start",
    stop: "Stop",
    youWon: "You won",
    congrats: "Congratulations! Well done!",
    nextStep: "Show this to an Elgiganten employee in the store",
    leaderboard: {
      headline: "Winners",
      date: "Date",
      username: "Username",
      attempts: "Attempts",
    },
    honorable: {
      headline: "Honorable Mentions",
      none: "No honorable mentions yet",
      winnersOnly: "Only winners!",
      noWinners: "There are no winners at the moment.",
    },
    toast: {
      success: "You have been added to the leaderboard",
    },
  },
  countdown: {
    endsIn: "ends in",
    hasEnded: "has ended",
    days: {
      plural: "days",
      singular: "day",
    },
    hours: {
      plural: "hours",
      singular: "hour",
    },
    minutes: {
      plural: "minutes",
      singular: "minute",
    },
    seconds: {
      plural: "seconds",
      singular: "second",
    },
  },
  days: {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  },
  product: {
    discount: "Save",
    btn: {
      label: "See the product",
    },
  },
  settings: {
    tabs: {
      yourInfo: "Your information",
      profileImage: "Profile image",
      connections: "Connections",
      themes: "Themes",
      preferences: "Preferences",
      changePassword: "Change password",
      otherSettings: "Other settings",
      language: "Language",
    },
    password_reset: {
      head: "Update/reset your account's password",
      steps: {
        1: "Enter email to recieve a 6 digit reset code",
        2: "Enter received 6 digit reset code",
        3: "Enter new password",
        4: "Repeat new password",
      },
    },
  },
  // Feed
  feed: {
    welcome_msg: "Welcome back, ",
  },

  profile: {
    tabs: {
      posts: "Feed",
      challenges: "Challenges",
      community: "Community",
      gathers: "Gathers",
      teams: "Teams",
      achievements: "Achievements",
    },
    challenges: {
      badges: {
        all: "all",
        participatingIn: "participatingIn",
        createdBy: "createdBy",
        favorites: "favorites",
        private: "private",
        completed: "completed",
        drafts: "drafts",
      },
    },
    community: {
      heading: "community",
      tabs: {
        friends_singular: "friend",
        friends_plural: "friends",
        following_singular: "following",
        following_plural: "following",
        followers_singular: "follower",
        followers_plural: "followers",
        empty_string1: "does not have any", // {userName} does not have any {currentTab} yet.
        empty_string2: "yet", // {userName} does not have any {currentTab} yet.
        following_empty_string1: "does not have a", // {userName} does not have a {currentTab} yet.
        following_empty_string2: "yet", // {userName} does not have a {currentTab} yet.
      },
      search: {
        placeholder: "Search by nickname",
      },
    },
    friends: {
      title: "Friends",
      no_friends_msg: "No friends added",
    },
    edit: {
      title: "Edit profile",
      tabs: {
        Avatar: "Avatar",
        Information: "Information",
        Connections: "Connections",
        Themes: "Themes",
      },
      avatar: {
        edit_msg: "Edit avatar",
        upload_msg: "Upload an image or pick one of our pre-defined avatars.",
        upload_title: "My uploads",
      },
      connections: {
        title: "Connecting your account",
        // In between desc1 and desc2 is the brands name
        desc1: "Connect your",
        desc2:
          "account with third-parties to participate in challenges. You can revoke access to your data at any time. Passwords and payment details will never be shared.",
        no_connections: "No connections",
        // In between desc1 and desc2 is the brands name
        no_connections_desc1: "Unlock all",
        no_connections_desc2: "features by connecting your accounts",
      },
      themes: {
        choose_msg:
          "Customize your World of Gamers experience by selecting a partner theme.",
      },
    },
    actions: {
      add_friend: "Add friend",
      unfriend: "Unfriend",
      cancel_friend_request: "Cancel request",
      accept_friend_request: "Accept friend request",
      decline_friend_request: "Decline friend request",
      follow: "Follow",
      unfollow: "Unfollow",
    },
  },
  // Overview archive (overview page banners + filters)| news, challenges, teams, gathers
  overview: {
    news: {
      create_btn: "Create new article",
      desc: "Most recent news of the World of Gamers and gaming industry",
      featured: "Featured news",
      headline: {
        mine: "My articles",
        editorial: "Editorial articles",
        popular: "Most popular",
        community: "Community articles",
      },
      badges: {
        mine: "Mine",
        editorial: "Editorial",
        popular: "Most popular",
        community: "Community created",
      },
    },
    challenges: {
      create_btn: "Create new challenge",
      desc: "Join or create challenges in your favourite games.",
      headline: {
        mine: "My challenges",
        new: "Discover new",
        zone: "Zone activity",
      },
      badges: {
        mine: "Mine",
        new: "Discover new",
        zone: "Zone activity",
      },
    },
    teams: {
      create_btn: "Create new team",
      desc: "Come together as a team to achieve great things!",
      headline: {
        mine: "My teams",
        new: "Discover new",
      },
      badges: {
        mine: "Mine",
        new: "Discover new",
      },
    },
    gathers: {
      create_btn: "Create new gather",
      desc: "Gather your friends or find new ones to shake things up!",
      headline: {
        mine: "My gathers",
        new: "Discover new",
      },
      badges: {
        mine: "Mine",
        new: "Discover new",
      },
    },
  },

  // About page
  about: {
    title: "About World of Gamers",
    desc: "      World of Gamers is a global community platform where gamers engage with other gamers as well as well-known brands. We wish to enable gamers to engage with each other through several means. As a platform we provide rich functionality to support the needs of the individual gamer and organizations while offering a unique possibility for our cooperative partners to engage with their intended key audience.",
    subsection1: {
      header: "What we do",
      desc1:
        "World of Gamers (“WOG”) is a gaming community that carries the DNA of more than 15 years of shaping eSport in Denmark and the Nordics.",
      desc2:
        "WOG is a gaming community with regional activities. We are using the gamification of statistics to activate our user and give them the option to create content for the whole community in a easy and accessible manner.",
    },
    subsection2: {
      header: "Who we are",
      desc1:
        "We are a team of passionate gamers who played our role in creating a strong foundation for eSport and raising the bar.",
      desc2:
        "World of Gamers are based on the Nordic model with good values and ethics, and is born out of our passion for gaming and our ambition to create something unique that has the potential to elevate gaming and eSport and set new standards.",
    },
    subheader2: "Who we are",
    subheader3: "The Nordic model",

    list: {
      1: "Bringing people together physically and/or digitally",
      2: "Uniting people based on a healthy and responsible community",
      3: "The ability for all participants to grow and develop their skills",
      4: "Providing safe and good experiences for all participants",
      5: "Uniting participants regardless of age, gender, and social background",
      6: "Contributing to giving participants increased value in their life",
      7: "Good values and ethics by treating fellow participants nicely",
      8: "Promoting happiness and passion through all activities",
    },
  },

  // Not found
  not_found: {
    title: "404 PAGE NOT FOUND",
    desc: "This page either does not exists, or is no longer available.",
    button: "Go back to Wog",
  },

  // help page
  help: {
    title: "Feedback",
    general_issues: {
      title: "General issues",
      desc: "Report issues that appeared during interacting with website:",
      list: {
        0: "using search field",
        1: "create button",
        2: "navigation, etc.",
      },
    },
    sign_up: {
      title: "Sign up process",
      desc: "Report issues on creating an account: ",
      list: {
        0: "performance",
        1: "occuring errors",
        2: "problems connecting through Battle.net or Steam platform, etc.",
      },
    },
    profile: {
      title: "Profile page",
      desc: "Report issues on profile page: ",
      list: {
        0: "connecting games account",
        1: "adding to friends",
        2: "editing profile",
        3: "notifications, etc.",
      },
    },
    create_challenge: {
      title: "Create a challenge",
      desc: "Report issues that appeared while creating a challenge: ",
      list: {
        0: "text formatting",
        1: "setting the dates",
        2: "choosing criterias, etc.",
      },
    },
    single_challenge: {
      title: "Single challenge",
      desc: "Report issues that appeared during interacting with single challenge page:  ",
      list: {
        0: "incorrect leaderboard",
        1: "wrong data",
        2: "participating in a challenge, etc.",
      },
    },
    challenge_overview: {
      title: "Challenges overview",
      desc: "Report issues that appeared during interacting with challenges overview page: ",
      list: {
        0: "incorrect leaderboard",
        1: "wrong data",
        2: "participating in a challenge, etc.",
      },
    },
  },
  // privacy policy
  privacy_policy: {},

  // terms of service
  term_of_service: {},

  // Sign up
  auth: {
    authcard: {
      unregistered: "Not a member yet?",
      create: "Create an account",
      registered: "Already a member?",
      login: "Log in",
      verify_email: "We have sent instructions for email verification to",
      forgot_password:
        "Enter the email you used to create your account with and we will send you a code for resetting your password.",
      or: "or",
    },
    login: {
      headline: "Log in to your account",
      forgot_password: "Don't remember your password?",
    },
    livestream: {
      headline: "Sign up or log in to participate",
    },
    create: {
      headline: "Create a new account",
      password: {
        headline: "Password must consist of at least",
        characters: "min. 8 characters",
        uppercase: "one uppercase character",
        lowercase: "one lowercase character",
        special: "one special character",
        number: "at least one number",
        match: "Passwords match",
      },
      profile: {
        headline: "Almost done",
        input: {
          username: {
            label: "Choose a unique username",
            placeholder: "Your username",
          },
        },
      },
      error: {
        invalid: "Email is not valid",
      },
    },
    forgot: {
      headline: "Forgot password",
      error: {
        invalid: "Email is not valid",
      },
    },
    verify: {
      headline: "Verify your email",
      validity:
        "Code is valid for 10 minutes after sending, if the code does not work, you can request a new one here:",
    },
    reset: {
      headline: "Reset your password",
      code: "Code from email",
      digits: "Enter the 6 digit code sent to",
    },
    input: {
      email: {
        label: "Your email address",
        placeholder: "email@domain.com",
      },
      username: {
        label: "Email or Username",
        placeholder: "email@domain.com, user name...",
      },
      password: {
        label: "Password",
        placeholder: "Your password",
        create: {
          label: "Create password",
          placeholder: "Choose a unique password",
          error: "Password does not meet the requirements",
        },
        repeat: {
          label: "Repeat password",
          placeholder: "Repeat your password",
          error: "Passwords dont match",
        },
      },
      button: {
        login: "Log in",
        create: "Create account",
        reset: "Reset",
        send_link: "Send link",
        cancel: "Cancel",
        quick_account: "Quick account",
        request: "Request a new code",
      },
    },
    terms: {
      1: "By continuing you agree to our",
      2: "Terms of use",
      3: "and",
      4: "Privacy Policy",
      5: "and confirm that you are at least 13 years old",
    },
    toast: {
      password: {
        wrong:
          "The password is invalid or this is not a password based account.",
      },
      profile: {
        incomplete: "Profile incomplete, please finish creating your profile.",
      },
    },
    log_into: "Log in to", // Will have the set brand name after "to"
    log_in_how: "How would you like to log in?",
    sign_up_how: "How would you like to sign up?",
    create_profile: "Create Your Profile",
    // auth step 2
    small_info:
      "you can always change your username, profile image and update your preferences in profile settings",
    pick_username: "Pick a username",
    profile_image: "DA Profile image",
    personalize: {
      small: "Personalizing",
      large: "Personalizing your user profile",
    },
    // ----
    // auth step 3
    all_set: "You are all set!",
    start_info:
      "DA Start your journey by connecting your game accounts or exploring the various challenges available.",
    // ----
    popup: {
      headline: "Win merch, prizes and DH coins",
      description:
        "Enter your {{credentialToUse}} and participate in our digital activities during Dreamhack Summer 2024.",
      terms:
        "I agree to be contacted if I win during the event and to possibly receive future offers and updates.",
      btn: "Join the fun",
      logout: "Log in with an existing account",
      credential: {
        email: {
          placeholder: "Enter your email address",
          label: "Email address",
        },
        phone: {
          placeholder: "Enter your phonenumber",
          label: "Phonenumber",
        },
      },
    },
  },

  // Basic Buttons text. other btn text's will be replaced with these for convenience
  // comments next to it is where where specific ones can be found
  // Words that are all capital should be capitalized in scss, not here.
  buttons: {
    upload: "Upload",
    save: "Save",
    save_changes: "Save changes",
    cancel: "Cancel",
    apply_theme: "Apply", // Theme picker
    remove_avatar: "Remove image", // Profile
    add_friend: "Add friend", // Profile
    unfriend: "Unfriend", // Profile
    manage_btn: "Manage account",
    follow: "Follow",
    unFollow: "Unfollow",
    log_in: "Log in",
    log_out: "Log out", // Navigation
    create: "Create", // Navigation
    see_more: "See more", // Home
    go_back: "Go back", // Home
    join: "Join", // Challenges cards
    get_started: "Get started now",
    try_btn: "Try it out",
    ready: "Ready", // Sign up 2
    connect_accounts: "Connect accounts", // Sign up 3
    explore_challenges: "Explore challenges",
    explore_teams: "Explore teams",
    skip_to_home: "Skip to homepage", // Sign up 3
    remove_btn: "Remove image",
    connect: "Connect",
    connected: "Connected",
    disconnect: "Disconnect",
  },

  // Strings used globaly
  strings: {
    step: "Step", // `Step` x of x
    of: "of", // Step x `of` x

    // Feature names, navigation, carousels etc
    challenges: "Challenges",
    challenge: "challenge", // non uppercase
    teams: "Teams",
    team: "team", // non uppercase
    news: "News",
    gathers: "Gathers",
    gather: "gather", // non uppercase
    feed: "Feed",
    friends: "Friends",
    friend: "friend", // non uppercase
    messages: "Messages",
    zones: "Zones",
    participants: "Participants", // Gathers
    interested: "Interested", // Gathers
    you_havent: "You haven't", // Feed
    user_hasnt: "This user hasn't", // Feed
    shared_anything: "shared anything yet.", // Feed
    share_something_friends: "Share something with your friends...", // Placeholder-inputs
    // Will have another word like "team, challenge, news" etc at the end
    share_something_about: "Share something about this", // Placeholder-inputs
    share_about_yourself: "Share something about yourself", // profile desc placeholder
    userName: "Username",
    fullName: "Full name",
    about: "About",
    uploadAvatar: "Upload avatar",
    sendResetPasswordCode: "Send reset password code",
    user_name: "Username (22 character limit)",
    user_name_placeholder: "Your username",
    full_name: "Full name",
    full_name_placeholder: "Tell us your name",
    about: "About",
    about_placeholder: "Tell others a bit about yourself",
    upload_avatar: "Upload avatar",
    send_password_reset_code: "Send reset password code",
  },
  // Single challenge
  challenge: {
    starts: "Starts in",
    ends: "Ends in",
    leaderboard: {
      headline: "Leaderboard",
      tabs: {
        ALL: "All",
        FRIENDS: "Friends",
        FOLLOWING: "Following",
      },
      score: {
        yours: "Your score",
        description: "Your results appear here as soon as you participate",
        noParticipants: "Be the first to participate and invite your friends",
        noFriends:
          "You don't have any friends actively participating in this challenge yet",
        noFollowing: "You are not following anyone on this account yet",
      },
    },
    description: {
      what: {
        headline: "What to do",
      },
      how: {
        headline: "How we calculate your score",
      },
    },
    buttons: {
      tabs: {
        back: "Back to all",
      },
      join: "Join",
      joinThisChallenge: "Join this challenge",
      joined: "Joined",
      submit: "Submit score",
    },
    criteria: {
      headline: "How is the score calculated",
    },
    terms: {
      heading: "Terms",
      preBrand: "You need to be present at the event and show up at the ",
      postBrand:
        "booth the day you have won and within opening hours in order to claim your prize. If you are unable to show up, then we can unfortunately not grand you your otherwise well deserved prize. It will instead be awarded to the next player on the leaderboard.",
    },
    participants: {
      participating: "participating",
      and: "and",
      more: "more",
    },
    disclaimers: {
      CSGO: "CS2 is a registered trademark of Valve. Trademarks are the property of their respective owners. Game materials copyright Valve. Valve has not endorsed and is not responsible for this site or its content.",
      Dota2:
        "Dota2 is a registered trademark of Valve. Trademarks are the property of their respective owners. Game materials copyright Valve. Valve has not endorsed and is not responsible for this site or its content.",
      PUBG: "PUBG is a registered trademark of PUBG Corporation. Trademarks are the property of their respective owners. Game materials copyright PUBG Corporation. PUBG Corporation has not endorsed and is not responsible for this site or its content.",
      f12022:
        "F1® 22 Game is official product of the FIA FORMULA ONE WORLD CHAMPIONSHIP. EA, EA SPORTS, the EA SPORTS logo, Codemasters, EGO and the Codemasters logo are trademarks of Electronic Arts Inc. Trademarks are the property of their respective owners. Electronic Arts Inc. has not endorsed and is not responsible for this site or its content.",
      f12023:
        "F1® 23 Game is official product of the FIA FORMULA ONE WORLD CHAMPIONSHIP. EA, EA SPORTS, the EA SPORTS logo, Codemasters, EGO and the Codemasters logo are trademarks of Electronic Arts Inc. Trademarks are the property of their respective owners. Electronic Arts Inc. has not endorsed and is not responsible for this site or its content.",
      fallguys:
        "Fall Guys is a registered trademark of Mediatonic Limited. Trademarks are the property of their respective owners. Game materials copyright Mediatonic Limited. Mediatonic Limited has not endorsed and is not responsible for this site or its content.",
      fh5: "Forza Horizon is a registered trademark of Microsoft Studios. Trademarks are the property of their respective owners. Game materials copyright Microsoft Studios. Microsoft Studios has not endorsed and is not responsible for this site or its content.",
      fm8: "Forza Motorsport is a registered trademark of Microsoft Studios. Trademarks are the property of their respective owners. Game materials copyright Microsoft Studios. Microsoft Studios has not endorsed and is not responsible for this site or its content.",
      fortnite:
        "Fortnite is a registered trademark of Epic Games. Trademarks are the property of their respective owners. Game materials copyright Epic Games. Epic Games has not endorsed and is not responsible for this site or its content. As an Amazon Associate we earn from qualifying purchases.",
    },
    highlights: {
      heading: "Latest highlights",
      pubg: "Get some kills in PUBG to appear in our highlights",
      fortnite: "Get some kills in Fortnite to appear in our highlights",
      new: "New",
      description: "Join the challenge and play to appear in highlights",
    },
  },
  logitech: {
    keyWithLink:
      "Hey this is a <1>LINK</1>! Also called <strong>hyperlink</strong>",
    signup: {
      headline: "Welcome to the Logitech McLaren G Challenge Race Days! ",
      description:
        "Set your lap time in Forza Motorsport and compete against the other drivers in your store and region. Check results weekly at www.logitechgchallenge.com/race-days and the Logitech G Discord.",
    },
    input: {
      headline: "Sign up to start",
      email: {
        label: "Email",
        placeholder: "john@doe.com",
      },

      store: {
        label: "Store",
        placeholder: "Enter your store",
      },
      fullName: {
        label: "Name First & Last ",
        placeholder: "John Doe",
      },
      userName: {
        label: "Username",
        placeholder: "Enter your username",
      },
      dateOfBirth: {
        label: "Date of Birth",
        placeholder: "Enter your date of birth",
      },
      terms: {
        headline: "Terms and Conditions",
        label: "I agree to the above",
        description:
          "All participants must read and understand the Terms & Conditions before submitting a contest entry.",
        descriptionWithLink:
          "All participants must read and understand the <1>Terms & Conditions</1> before submitting a contest entry.",
      },
      submit: {
        label: "Sign up",
      },
    },
  },

  elgiganten: {
    input: {
      email: {
        label: "Email",
        placeholder: "john@doe.com",
      },

      firstName: {
        label: "First name ",
        placeholder: "John",
      },

      lastName: {
        label: "Last name",
        placeholder: "John Doe",
      },

      phoneNumber: {
        label: "Phone number",
        placeholder: "+123456789",
      },

      street: {
        label: "Full street name / Address",
      },

      postalCode: {
        label: "Zip code",
      },

      country: {
        label: "Country ",
      },

      city: {
        label: "City",
      },

      stateProvince: {
        label: "State / Province",
        placeholder: "State or Province name",
      },
    },
  },
};

export default TRANSLATIONS_EN;
