import React, { useState } from "react";

import { BaseTextInput } from "./../../../src/components/BaseTextInput/BaseTextInput";
import { Button } from "./../../../src/components/Button/index";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { createToast } from "@/utils/toaster/createToast";

export const Challenge1337PageReset = () => {
  const password = "1337reset";
  const [passValue, setPassvalue] = useState<string>("");
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);

  const date = new Date();
  const calenderDate = date.toDateString();
  const dateHour = date.getHours();
  const dateMinutes = date.getMinutes();
  const dateSlug = `${(calenderDate as any).replaceAll(
    " ",
    "-"
  )}-${dateHour}-${dateMinutes}`.toLowerCase();

  const handle1337Reset = async () => {
    if (passValue === password) {
      const data1337ref = collectionRef.winners1337
        .doc("elgiganten_livestream")
        .collection("records");

      const currentActiveCollectionRef = await data1337ref
        .where("active", "==", true)
        .limit(1)
        .get();

      const currentActiveDocument = currentActiveCollectionRef.docs[0];

      await data1337ref
        .doc(currentActiveDocument.id)
        .update({
          active: false,
        })
        .then(() => {
          createToast({
            type: "info",
            message: "Current winners list saved and ended",
          });
        });

      await data1337ref
        .doc(dateSlug)
        .set({
          active: true,
        })
        .then(() => {
          createToast({
            type: "success",
            message: "New winners list started.",
          });
        });

      setPassvalue("");
      setInputDisabled(true);
    }
  };

  return (
    <div className="leet__reset">
      <h2>To reset the 1337 leaderboard, enter the password</h2>
      <BaseTextInput
        label={
          "Resetting will save and end the old leaderboard list, and start a new list."
        }
        textType="password"
        value={passValue}
        setSingleState={(e) => setPassvalue(e)}
        isDisabled={inputDisabled}
      />

      <Button
        variant="primary"
        disabled={passValue !== password}
        onClickAction={handle1337Reset}
      >
        Reset 1337
      </Button>
    </div>
  );
};
