import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import i18next from "i18next";
import React from "react";
import { winner1337DocumentType } from "./helperFunctions/uploadNewContactFormDocument";

export const ContactForm1337UI = ({
  setFieldValue,
  loading,
  formData,
}: {
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  loading: boolean;
  formData: winner1337DocumentType;
}) => {
  return (
    <div>
      {/* First / last name */}
      <div className="input-group">
        {/* FirstName */}
        <div className="input">
          <BaseTextInput
            label={i18next.t("elgiganten.input.firstName.label")}
            textType="text"
            value={formData?.firstName.trim()}
            placeholder={i18next.t("elgiganten.input.firstName.placeholder")}
            setObjectState={setFieldValue}
            setObjectStateTargetName="firstName"
            isDisabled={loading}
          />
        </div>

        {/* LastName */}
        <div className="input">
          <BaseTextInput
            label={i18next.t("elgiganten.input.lastName.label")}
            textType="text"
            value={formData?.lastName}
            placeholder={i18next.t("elgiganten.input.lastName.placeholder")}
            setObjectState={setFieldValue}
            setObjectStateTargetName="lastName"
            isDisabled={loading}
          />
        </div>
      </div>

      {/* Email */}
      <div className="input">
        <BaseTextInput
          label={i18next.t("elgiganten.input.email.label")}
          textType="text"
          value={formData?.email}
          placeholder={i18next.t("elgiganten.input.email.placeholder")}
          setObjectState={setFieldValue}
          setObjectStateTargetName="email"
          isDisabled={loading}
        />
      </div>

      {/* Phone */}
      <div className="input">
        <BaseTextInput
          label={i18next.t("elgiganten.input.phoneNumber.label")}
          textType="text"
          value={formData?.phoneNumber}
          placeholder={i18next.t("elgiganten.input.phoneNumber.placeholder")}
          setObjectState={setFieldValue}
          setObjectStateTargetName="phoneNumber"
          isDisabled={loading}
        />
      </div>

      {/* Street */}
      <div className="input">
        <BaseTextInput
          label={i18next.t("elgiganten.input.street.label")}
          textType="text"
          value={formData?.street}
          placeholder={""}
          setObjectState={setFieldValue}
          setObjectStateTargetName="street"
          isDisabled={loading}
        />
      </div>

      {/* Postal / county */}
      <div className="input-group">
        {/* Postal */}
        <div className="input">
          <BaseTextInput
            label={i18next.t("elgiganten.input.postalCode.label")}
            textType="text"
            value={formData?.postalCode}
            placeholder={""}
            setObjectState={setFieldValue}
            setObjectStateTargetName="postalCode"
            isDisabled={loading}
          />
        </div>

        {/* city */}
        <div className="input">
          <BaseTextInput
            label={i18next.t("elgiganten.input.city.label")}
            textType="text"
            value={formData?.city}
            placeholder={""}
            setObjectState={setFieldValue}
            setObjectStateTargetName="city"
            isDisabled={loading}
          />
        </div>
      </div>

      {/* Country */}
      <div className="input">
        <BaseTextInput
          label={i18next.t("elgiganten.input.country.label")}
          textType="text"
          value={formData?.country}
          placeholder={""}
          setObjectState={setFieldValue}
          setObjectStateTargetName="country"
          isDisabled={loading}
        />
      </div>
    </div>
  );
};
