import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebase from "firebase/app";
import { createToast } from "@/utils/toaster/createToast";
import { Spinner } from "@/components/Spinner";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { createQuickAccountUser } from "@/utils/sharedHelperFunctions";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { SignupLogitech } from "../Signup.Logitech";

export const ScannedQR = (): JSX.Element => {
  const { pcId } = useParams();

  const client = pcId?.split("/").at(-1).split("_")[0];
  const pcIdCode = pcId?.split("/").at(-1).split("_")[1];

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const responded = useRef(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const user = useAuthPersistState((state) => state.user);
  const setUser = useAuthPersistState((state) => state.setUser);

  const [QRresponse, loadingQr, qrError, fetch_qr] = useCloudFunctionCall<
    "Desktop is in use!" | "Success!" | "Wrong QR!" | undefined
  >("scanQRForOverwolf");

  let responseString = QRresponse;

  useEffect(() => {
    responseString = undefined;
  }, [loadingQr]);

  useEffect(() => {
    responseString = QRresponse;
  }, [QRresponse]);

  const handleScanQr = async () => {
    if (client) {
      localStorage.setItem("client", client);
    }

    if (!pcIdCode) {
      console.log("PC code missing!");

      return createToast({
        type: "error",
        message: "PC code missing!",
      });
    }
    setLoading(true);

    await fetch_qr({
      QR_id: pcIdCode,
    })
      .then((res) => {
        setTimeout(() => {
          if (responseString) {
            responded.current = true;
          }

          if (responseString === "Success!") {
            return createToast({
              type: "success",
              message: responseString,
            });
          }

          if (QRresponse !== "Success!") {
            return createToast({
              type: "info",
              message: responseString,
            });
          }

          responseString = undefined;
        }, 2000);
      })

      .then(async () => {
        const challenge = await firebase
          .firestore()
          .collection("challenges")
          .where("status", "==", "ongoing")
          .where("client", "==", client)
          .where("eventCode", "==", brand.event.eventCode)
          .get();

        const challData = challenge?.docs?.[0]?.data();

        setTimeout(() => {
          setLoading(false);
          if (challData && challData.slug) {
            navigate(`${siteRoutes["challengesAndSlug"]}${challData.slug}`);
            return window.location.reload();
          } else {
            if (brand.brandValue === "LOGITECH") {
              return navigate("/profile");
            }

            if (client) {
              localStorage.setItem("client", client);

              if (
                client === "TCL" &&
                !user?.email &&
                !currentUser?.email &&
                !currentUser?.emailAddress
              ) {
                navigate(`${siteRoutes["partnersAndSlug"]}${client}popup`);
              } else if (client === "TCL") {
                navigate(`${siteRoutes["partnersAndSlug"]}${client}`);
              } else {
                return navigate("/profile");
              }
            } else {
              return navigate("/profile");
            }
          }
        }, 3000);
      })

      .catch((e) => {
        responded.current = true;

        return createToast({
          type: "error",
          message: e.message,
        });
      });
  };

  const handleLogInAnonymously = async () => {
    setLoading(true);

    await createQuickAccountUser(setProfile)
      .then(async () => {
        setUser(user);
        await handleScanQr();
      })
      .catch(() => {
        return createToast({
          type: "error",
          message: "Something went wrong, please contact a admin for support.",
        });
      });
  };

  useEffect(() => {
    if (!currentUser) {
      if (client) {
        localStorage.setItem("client", client);
      }

      if (brand && brand.brandValue !== "LOGITECH") {
        handleLogInAnonymously();
      }
    } else {
      handleScanQr();
    }
  }, []);

  if (brand.brandValue === "LOGITECH" && !loading) {
    return <SignupLogitech customEvent={handleScanQr} />;
  }

  return (
    <>{loading ? <Spinner /> : <div className="text-safe">Success</div>}</>
  );
};
