import Dummy from "@/dummy";
import { NewsOverview, SingleNews } from "@/features/news";
import { FeedPage, SinglePostPage } from "@/features/feed";
import {
  ProfilePage,
  ProfileSettings,
  ProfileTabDataMobileFetcher,
} from "@/features/profile";
import {
  ChallengeOverviewPage,
  CreateChallengePage,
  SingleChallengePage,
  SubmitScore,
} from "@/features/challenge";
import {
  CreateTeamForm,
  SingleTeamFetcher,
  TeamsOverviewFetcher,
} from "@/features/team";
import { GatherOverview } from "@/features/gather";
import { PageDoesntExist } from "@/pageDoesntExist";
import { SingleGather } from "@/features/gather/components/single/SingleGather.fetcher";
import { NotificationPage } from "@/features/notifications/components/page/NotificationPage.fetcher";
import { siteRoutes } from "./siteRoutesList";

import { brand } from "./../utils/brandVariables/brandVariables";
import CreateGatherForm from "@/features/gather/components/create/CreateGather.fetcher";
import ChatOverview from "@/features/messages/Messages.fetcher";
import SingleGatherSettings from "@/features/gather/components/single/SingleGatherSettings";
import { TermsOfUse } from "../../branchAndBrandSpecific/features/staticPages/TermsOfUse";
import { PrivacyPolicy } from "../../branchAndBrandSpecific/features/staticPages/PrivacyPolicy";
import HomePage from "@/features/home/Home";
import { CommunityPage } from "@/features/profile/pages/CommunityPage";
import TrackerPage from "@/features/tracker/TrackerPage";
import { SingleTeamSeeAllMembers } from "@/features/team/components/pages/SingleTeamSeeAllMembers";
import { Winners } from "@/features/winners/Winners";
import { Schedule } from "@/features/schedule/Schedule";
import { ScannedQR } from "@/features/auth/pages/eventQrSignUp/ScannedQR";
import { EventQrCode } from "@/features/auth/pages/eventQrSignUp/EventsQr";
import JBLPartnerPage from "../features/partners/jbl.page";
import JBLAimTrialPage from "../features/partners/JBLAimTrialPage.tsx";
import LogitechPage from "../features/partners/LogitechGChallenge.page";
import PCSpecialistPartnerPage from "../features/partners/pcspecialist.page";
import TCLPartnerPage from "../features/partners/tcl.page";
import Challenge1337Page from "../features/1337-challenge/1337.challenge.page";
import RacingPartnerPage from "../features/partners/racing.page";
import CSPartnerPage from "../features/partners/cs2.page";
import { DailyTasks } from "./../features/dailyTasks/DailyTasks.fetcher";
import { GamesPage } from "./../features/games/Games.page";
import ChallengesPage from "./../../src/features/challenge/pages/challenges.overview.alternative.page";
import SingleChallengeAltPage from "@/features/challenge/pages/challenges.single.alternative.page";
import SingleChallengeEventPage from "@/features/challenge/pages/SingleChallengeEventPage";
import { TreasureHunt } from "../../branchAndBrandSpecific/features/treasure-hunt/TreasureHunt";
import { tclLogsPage } from "./../features/partners/tcl.logs.page";
import { SingleChallengePageRenderLogic } from "@/features/challenge/pages/SingleChallengePageRenderLogic";
import { EventActivity } from "./../features/eventActivity/EventActivity";
import { Challenge1337PageReset } from "./../features/1337-challenge/1337.challenge.page.reset";

export const loggedInRoutes: {
  element: (() => JSX.Element) | React.FunctionComponent;
  path: string;
}[] = [
  // AUTH AFTER SIGN UP
  {
    element: PageDoesntExist,
    path: "/signincallback/",
  },

  // PROFILE
  {
    element: () => ProfilePage({ belongToCurrentUser: true }),
    path: siteRoutes["profile"], // route to self profile
  },
  {
    element: ProfilePage,
    path: `${siteRoutes["profileAndSlug"]}:slug`, // route to other user's profile
  },
  {
    element: ProfileSettings,
    path: siteRoutes["profileSettings"], // route to other user's profile
  },
  {
    element: ProfileTabDataMobileFetcher,
    path: `${siteRoutes["profileTabAndSlug"]}:slug`, // route to other user's profile
  },

  {
    element: CommunityPage,
    path: `${siteRoutes["profileCommunityAndSlug"]}:slug`,
  },

  // NEWS
  {
    element: !brand.hasFeature.news ? PageDoesntExist : NewsOverview,
    path: siteRoutes["news"], // route to news overview
  },
  {
    element: !brand.hasFeature.news ? PageDoesntExist : SingleNews,
    path: `${siteRoutes["newsAndSlug"]}:slug`, // route to news overview
  },

  // FEED/POSTS
  {
    element: FeedPage,
    path: siteRoutes["dashboard"],
  },
  {
    element: SinglePostPage,
    path: `${siteRoutes["singlePostAndSlug"]}:slug`,
  },

  // CHALLENGES
  {
    element: !brand.hasFeature.challenges
      ? PageDoesntExist
      : ChallengeOverviewPage,
    path: siteRoutes["challenges"],
  },
  {
    element: !brand.hasFeature.singleChallenge
      ? PageDoesntExist
      : SingleChallengePageRenderLogic,
    path: `${siteRoutes["challengesAndSlug"]}:slug`,
  },
  {
    element: !brand.hasFeature.challenges
      ? PageDoesntExist
      : () => CreateChallengePage({ isEdit: false, setIsEdit: undefined }),
    path: siteRoutes["createChallenge"], // route to challenges overview
  },
  {
    element:
      !brand.hasFeature.manualSubmissions || !brand.hasFeature.challenges
        ? PageDoesntExist
        : SubmitScore,
    path: `${siteRoutes["submitScoreAndSlug"]}:slug`, // route to challenge submit score page
  },

  // Test flow for challenges
  {
    element: ChallengesPage,
    path: `${siteRoutes["newchallenges"]}`,
  },
  {
    element: SingleChallengeAltPage,
    path: `${siteRoutes["newchallenge"]}:slug`,
  },

  // GATHERs
  {
    element: !brand.hasFeature.gathers ? PageDoesntExist : GatherOverview,
    path: siteRoutes["gathers"],
  },
  {
    element: !brand.hasFeature.gathers ? PageDoesntExist : SingleGather,
    path: `${siteRoutes["GathersAndSlug"]}:slug`,
  },
  {
    element: !brand.hasFeature.gathers ? PageDoesntExist : CreateGatherForm,
    path: siteRoutes["createGather"],
  },
  {
    element: !brand.hasFeature.gathers ? PageDoesntExist : SingleGatherSettings,
    path: `${siteRoutes["gatherSettings"]}:slug`,
  },

  // TEAMS
  {
    element: !brand.hasFeature.teams ? PageDoesntExist : TeamsOverviewFetcher,
    path: siteRoutes["teams"],
  },
  {
    element: !brand.hasFeature.teams ? PageDoesntExist : SingleTeamFetcher,
    path: `${siteRoutes["teamsAndSlug"]}:slug`,
  },
  {
    element: !brand.hasFeature.teams
      ? PageDoesntExist
      : () => CreateTeamForm({ isEdit: false }),
    path: siteRoutes["createTeam"],
  },

  {
    element: !brand.hasFeature.teams
      ? PageDoesntExist
      : SingleTeamSeeAllMembers,
    path: `${siteRoutes["teamsMembersAndSlug"]}:slug`,
  },

  // MESSAGES
  {
    element: !brand.hasFeature.messages ? PageDoesntExist : ChatOverview,
    path: siteRoutes["messages"],
  },
  {
    element: !brand.hasFeature.messages ? PageDoesntExist : PageDoesntExist,
    path: `${siteRoutes["messagesAndSlug"]}:slug`,
  },

  // QR SIGN UP AND EVENT TRACKET
  {
    element: EventQrCode,
    path: siteRoutes["eventQrCode"],
  },
  {
    element: ScannedQR,
    path: `${siteRoutes["scanQrCodeAndSlug"]}:pcId`,
  },

  // NOTIFICATIONS
  {
    element: NotificationPage,
    path: siteRoutes["notifications"],
  },

  {
    path: "/landing",
    element: PageDoesntExist,
  },
  {
    path: "/",
    element: PageDoesntExist,
  },

  // OTHER | UNUSED

  {
    element: HomePage,
    path: siteRoutes["home"],
  },
  {
    element: TrackerPage,
    path: siteRoutes["tracker"],
  },
  {
    element: TermsOfUse,
    path: siteRoutes["terms"],
  },
  {
    element: PrivacyPolicy,
    path: siteRoutes["privacy-policy"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["help"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["about"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["zones"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["friends"],
  },

  {
    element: brand.hasFeature.winners ? Winners : PageDoesntExist,
    path: siteRoutes["winners"],
  },
  {
    element: brand.hasFeature.schedule ? Schedule : PageDoesntExist,
    path: siteRoutes["schedule"],
  },
  {
    element: brand.hasFeature.dailytasks ? DailyTasks : PageDoesntExist,
    path: siteRoutes["dailytasks"],
  },

  // Elgiganten Event site
  {
    element: brand.brandValue === "ELGIGANTEN" ? HomePage : PageDoesntExist,
    path: siteRoutes["partners"],
  },
  {
    element:
      brand.brandValue === "ELGIGANTEN" ? TCLPartnerPage : PageDoesntExist,
    path: siteRoutes["tcl"],
  },
  {
    element:
      brand.brandValue === "ELGIGANTEN" ? TCLPartnerPage : PageDoesntExist,
    path: siteRoutes["tcl_popup"],
  },

  {
    element:
      brand.brandValue === "ELGIGANTEN"
        ? Challenge1337PageReset
        : PageDoesntExist,
    path: siteRoutes["leet-reset-trigger"],
  },

  // {
  //   element:
  //     brand.brandValue === "ELGIGANTEN" ? GChallengePage : PageDoesntExist,
  //   path: siteRoutes["logitech"],
  // },
  {
    element: brand.brandValue === "LOGITECH" ? LogitechPage : PageDoesntExist,
    path: siteRoutes["gchallenge"],
  },

  {
    element:
      brand.brandValue === "ELGIGANTEN" ? JBLAimTrialPage : PageDoesntExist,
    path: siteRoutes["jbl"],
  },
  {
    element:
      brand.brandValue === "ELGIGANTEN" ? RacingPartnerPage : PageDoesntExist,
    path: siteRoutes["racing"],
  },
  {
    element:
      brand.brandValue === "ELGIGANTEN" ? CSPartnerPage : PageDoesntExist,
    path: siteRoutes["cs2"],
  },
  {
    element:
      brand.brandValue === "ELGIGANTEN"
        ? PCSpecialistPartnerPage
        : PageDoesntExist,
    path: siteRoutes["pcspecialist"],
  },
  {
    element:
      brand.brandValue === "ELGIGANTEN" ? Challenge1337Page : PageDoesntExist,
    path: siteRoutes["leet"],
  },
  {
    element: brand.hasFeature.games ? GamesPage : PageDoesntExist,
    path: siteRoutes["gamesPage"],
  },

  {
    element: brand.hasFeature.treasure_hunt ? TreasureHunt : PageDoesntExist,
    path: `${siteRoutes["treasureHunt"]}:thid`,
  },
  {
    element: brand.hasFeature.treasure_hunt ? TreasureHunt : PageDoesntExist,
    path: siteRoutes["treasureHunt"],
  },
  {
    element: tclLogsPage,
    path: siteRoutes["tcl_logs"],
  },

  // Activity

  {
    element: brand.hasFeature.activities ? EventActivity : PageDoesntExist,
    path: `${siteRoutes["activityAndSlug"]}:slug`,
  },
];
