import React, { FC, useEffect, useState } from "react";
import LandingCallToAction from "@/features/landing/components/CallToAction";
import ctaImage from "../../../src/features/landing/assets/elgRacing.webp";
import { getChallengeBasedOnClient } from "../../../branchAndBrandSpecific/helperFunctions/getChallengeBasedOnClient";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useAtom } from "jotai";

const RacingPartnerPage: FC = (): JSX.Element => {
  const [challengeLink, setChallengeLink] = useState<string>("");
  const [client, setCurrentClient] = useAtom(clientAtom);
  setCurrentClient("ELGIGANTEN");

  // Fetch current active challenge logic
  const fetch = async () => {
    await getChallengeBasedOnClient(client, "ongoing").then((res) => {
      if (res.slug) {
        setChallengeLink(`/challenges/${res.slug}`);
      }
    });
  };

  // Fetch current active challenge on page load
  useEffect(() => {
    if (client) {
      fetch();
    }
  }, [client]);

  return (
    <div className="partner">
      <LandingCallToAction
        title={"F1® 24: Become the fastest on the track"}
        desc={
          "Are you fast enough to make it onto the leaderboard? Try out a Formula 1 racer in F1® 24 and set your best lap time. Race on an iconic track and battle for the top spot on the leaderboard. The fastest time wins more than just glory - are you ready to push the accelerator to the limit?"
        }
        image={ctaImage}
        link={challengeLink}
        label={challengeLink !== "" ? "Participate" : ""}
        float="left"
      />
    </div>
  );
};

export default RacingPartnerPage;
