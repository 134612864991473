import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import firebase from "firebase/app";

export interface winner1337DocumentType {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  street: string;
  postalCode: string;
  country: string;
  city: string;
  stateProvince: string;
}

export const UploadNewContactFormDocument = async ({
  data,
}: {
  data: winner1337DocumentType;
}) => {
  const dbref = firebase
    .firestore()
    .collection("1337-winners-contact")
    .doc(brand.event.eventCode)
    .collection("records");

  const docID = dbref.doc().id;

  const response = await dbref
    .doc(docID)
    .set({
      ...data,
      submitDate: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      console.log("Success");
      return true;
    })
    .catch((err) => {
      console.log("err,", err);

      return false;
    });

  if (response) {
    return true;
  }
};
