import { useState } from "react";
import { ContactForm1337UI } from "./1337.contact.form.ui";
import {
  UploadNewContactFormDocument,
  winner1337DocumentType,
} from "./helperFunctions/uploadNewContactFormDocument";
import { ReactComponent as IconElgiganten } from "../../../../branchAndBrandSpecific/utils/brandVariables/assets/elgiganten/elgiganten.svg";
import { Button, Spinner } from "@/components";

export const ContactForm1337 = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [success, setSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState<winner1337DocumentType>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    street: "",
    postalCode: "",
    country: "Sweden",
    city: "",
    stateProvince: "",
  });
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
  const isPhoneValid =
    /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/.test(
      formData.phoneNumber
    );

  const handleSubmit = async () => {
    setLoading(true);

    if (!formData.firstName) {
      setLoading(false);
      return setError(new Error("Firstname cannot be empty!"));
    }

    if (!formData.lastName) {
      setLoading(false);
      return setError(new Error("Lastname cannot be empty!"));
    }

    if (!isEmailValid) {
      setLoading(false);
      return setError(new Error("Email is not a valid format!"));
    }

    if (!isPhoneValid) {
      setLoading(false);
      return setError(new Error("Phone number is not a valid format!"));
    }

    if (!formData.street) {
      setLoading(false);
      return setError(new Error("Street cannot be empty!"));
    }

    await UploadNewContactFormDocument({
      data: formData,
    })
      .then((res) => {
        console.log("success");
        if (res) {
          setSuccess(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);

        setLoading(false);
      });
  };

  const setFieldValue = async (targetName: string, value: any) => {
    return setFormData((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  return (
    <div className="winner1337Form">
      <section className="winner1337Form__header">
        <IconElgiganten />
      </section>
      <section>
        <ContactForm1337UI
          setFieldValue={setFieldValue}
          formData={formData}
          loading={loading}
        />
      </section>
      <section className="winner1337Form__error">
        <p>{error && error.message}</p>
      </section>
      <section className="winner1337Form__actions">
        {!success ? (
          <Button
            variant={"primary"}
            disabled={loading}
            onClickAction={() => {
              handleSubmit();
            }}
          >
            {loading ? <Spinner /> : "Submit form"}
          </Button>
        ) : (
          <>
            <h2>Form submitted, thank you,</h2>
            <h3>we will contact you when you win a prize!.</h3>
          </>
        )}
      </section>
    </div>
  );
};
